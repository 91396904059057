@use 'common'

@font-face
	font-family: 'museoSansRounded'
	src: url('/fonts/museosansrounded-500-webfont.woff2') format('woff2'), url('/fonts/museosansrounded-500-webfont.woff') format('woff')
	font-weight: 500
	font-style: normal

@font-face
	font-family: 'museoSansRounded'
	src: url('/fonts/museosansrounded-700-webfont.woff2') format('woff2'), url('/fonts/museosansrounded-700-webfont.woff') format('woff')
	font-weight: 700
	font-style: normal

html,
body
	background-color: common.$background-color
	color: #000000
	padding: 0
	margin: 0
	font-family: museoSansRounded, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box

:root
	--global-horizontal-spacing: 1rem

	--content-width-normal: #{common.$contentWidth-normal}
	--content-width-wide: #{common.$contentWidth-wide}

	@media (min-width: common.$break62)
		--global-horizontal-spacing: 2rem

.swiper-wrapper
	position: relative
	width: 100%
	height: 100%
	z-index: 1
	display: flex
	justify-content: space-between
	transition-property: transform
	transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial)
	box-sizing: content-box

.swiper-slide
	flex-shrink: 0
	height: 100%
	position: relative
	transition-property: transform
	display: block

.swiper
	margin-inline: auto

