
$red: #EB1C23
$grey: #6B6B6B

$background-color: #FBF9F9

$break30: 30rem
$break48: 48rem
$break62: 62rem
$break75: 75rem

$contentWidth-normal: 68.75rem // 68.75rem == 1100px
$contentWidth-wide: 100rem // 100rem == 1600px
